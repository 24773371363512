<template>
  <div>
    <ReceipingInfo :participantId="-1"></ReceipingInfo>
  </div>
</template>

<script>
import ReceipingInfo from '@/components/receipingInfo/ReceipingInfo.vue'

export default {
  components: { ReceipingInfo },
}
</script>